export const WHITE = '#FFFFFF';
export const BLACK = '#000000';

export const GRAY_DARK = '#2C3032';
export const GRAY = '#23272A';
export const GRAY_MEDIUM = '#3A4045';
export const GRAY_LIGHT = '#BFBFBF';

export const MARINE_BLUE = '#1C4DB9';
export const MARINE_BLUE_MEDIUM = '#6B8BD1';
export const MARINE_BLUE_LIGHT = '#D1DBF1';

export const VEGGIE_GREEN = '#009b7d';
export const VEGGIE_GREEN_MEDIUM = '#59BEAA';
export const VEGGIE_GREEN_LIGHT = '#CCEBE5';

export const FLINK_PINK = '#E31C79';
export const FLINK_PINK_MEDIUM = '#ED6BA7';
export const FLINK_PINK_LIGHT = '#FCE7F1';

export const LIGHT_BLUE = '#5AA8EA';
export const LIGHT_BLUE_MEDIUM = '#93C6F1';
export const LIGHT_BLUE_LIGHT = '#DEEEFB';

export const SUNNY_YELLOW = '#F0D22B';
export const SUNNY_YELLOW_MEDIUM = '#F5E175';
export const SUNNY_YELLOW_LIGHT = '#FCF6D4';

export const RED = '#D21212';
export const RED_MEDIUM = '#EBCCCC';
export const RED_LIGHT = '#F6D9D9';

export const DEEP_BLUE = '#3B76FF';
export const CARREFOUR_BLUE = '#0970E6';
