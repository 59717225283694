import * as Types from './operations';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetHubStockChangeLogsDocument = gql`
    query getHubStockChangeLogs($input: GetHubStockChangeLogsInput!) {
  getHubStockChangeLogs(input: $input) {
    logs {
      createdAt
      reason
      sku
      name
      orderId
      userId
      quantityFrom
      quantityTo
    }
  }
}
    `;

/**
 * __useGetHubStockChangeLogsQuery__
 *
 * To run a query within a React component, call `useGetHubStockChangeLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHubStockChangeLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHubStockChangeLogsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetHubStockChangeLogsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetHubStockChangeLogsQuery, Types.GetHubStockChangeLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetHubStockChangeLogsQuery, Types.GetHubStockChangeLogsQueryVariables>(GetHubStockChangeLogsDocument, options);
      }
export function useGetHubStockChangeLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetHubStockChangeLogsQuery, Types.GetHubStockChangeLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetHubStockChangeLogsQuery, Types.GetHubStockChangeLogsQueryVariables>(GetHubStockChangeLogsDocument, options);
        }
export type GetHubStockChangeLogsQueryHookResult = ReturnType<typeof useGetHubStockChangeLogsQuery>;
export type GetHubStockChangeLogsLazyQueryHookResult = ReturnType<typeof useGetHubStockChangeLogsLazyQuery>;
export type GetHubStockChangeLogsQueryResult = Apollo.QueryResult<Types.GetHubStockChangeLogsQuery, Types.GetHubStockChangeLogsQueryVariables>;