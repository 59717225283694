import StockChangeLogs from './components/StockChangeLogs';
import { ThemeProvider } from 'styled-components';
import { colors } from 'theme/index';
import { CustomApolloProvider } from 'provider/CustomApolloProvider';

const App = ({ hubSlug }: { hubSlug: string }) => {
  return (
    <CustomApolloProvider>
      <ThemeProvider theme={{ colors }}>
        <div id="hp-stock-change-log-module">
          <StockChangeLogs hubSlug={hubSlug} />
        </div>
      </ThemeProvider>
    </CustomApolloProvider>
  );
};

export default App;
