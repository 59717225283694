import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import { memo, useCallback, useEffect, useRef, useState } from 'react';

import { useKeyPress, useOnClickOutside } from '@flpkg/hooks';
import { Option, SelectProps } from './types';

const Select = ({
  name,
  placeholder,
  options,
  selectedOption,
  onSelect,
  isDisabled,
  variant = 'large',
  displayValue = selectedOption?.label || '',
  testId = '',
}: SelectProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const escape = useKeyPress('Escape');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (escape && isOpen) {
      onClose();
    }
  }, [escape, isOpen, onClose]);

  useOnClickOutside(ref, null, () => {
    if (isOpen) {
      onClose();
    }
  });

  const onSelectOption = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    option: Option
  ) => {
    event.preventDefault();
    onClose();
    onSelect && onSelect(option);
  };

  const Icon = isOpen ? ChevronUpIcon : ChevronDownIcon;
  return (
    <div className={`${variant} relative my-4`} ref={ref}>
      <button
        className={`relative cursor-default log-filter`}
        onClick={() => setIsOpen(!isOpen)}
        disabled={isDisabled}
        type="button"
        data-testid={testId}
      >
        <input
          className={`block truncate text-base border-0 py-3 pr-8 w-full text-left placeholder-flinkGray-light cursor-default disabled:cursor-not-allowed caret-transparent`}
          readOnly
          value={displayValue}
          disabled={isDisabled}
          name={name}
          placeholder={placeholder}
          aria-label={name}
        />
        <span className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
          <Icon className="w-5 h-5 text-flinkGray" />
        </span>
      </button>
      {isOpen && (
        <ul className="absolute w-full min-w-48 z-10 bg-flinkGray max-h-96 rounded-md py-1 border-0 overflow-auto text-sm">
          {options.map((option: Option) => (
            <li key={option.value} className="hover:bg-flinkGray-medium">
              <button
                className="flex items-center justify-between p-2 my-0.5 cursor-default hover:bg-flinkGray-medium w-full border-b-0 text-base"
                onClick={(event) => onSelectOption(event, option)}
                type="button"
                disabled={option.disabled}
              >
                <span className="ml-3 block truncate">{option.label}</span>
                {selectedOption?.value === option.value && (
                  <span className="text-flinkPink">
                    <CheckIcon className="h-5 w-5 fill-current" />
                  </span>
                )}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default memo(Select);
