import { memo, useEffect, useState } from 'react';
import { SearchIcon, XIcon } from '@heroicons/react/solid';
import { DEBOUNCE_TIME_IN_MS } from 'lib/constants';
import { isNumeric, isValidSKU, truncateQueryString } from 'lib/helpers';

type SearchbarProps = {
  name?: string;
  placeholder?: string;
  onChange: (options: { queryString?: string; sku?: string }) => void;
  variant?: string;
};

const Searchbar = ({ name, placeholder, onChange, variant = 'large' }: SearchbarProps) => {
  const [querySearch, setQuerySearch] = useState<string>();
  useEffect(() => {
    const timer = setTimeout(() => {
      if (querySearch) {
        if (querySearch.length > 3 && !isNumeric(querySearch)) {
          onChange({ queryString: querySearch, sku: '' });
        }
        if (isValidSKU(querySearch)) {
          onChange({ queryString: '', sku: querySearch });
        }
      } else {
        onChange({ queryString: '', sku: '' });
      }
    }, DEBOUNCE_TIME_IN_MS);
    return () => clearTimeout(timer);
  }, [onChange, querySearch]);
  const Icon = querySearch ? XIcon : SearchIcon;

  return (
    <div className={`${variant} relative my-4 w-2/5`}>
      <div className="relative flex items-center bg-flinkGray rounded">
        <Icon className="pl-2 cursor-pointer w-8 h-8" onClick={() => setQuerySearch('')} />
        <input
          className="block truncate text-base border-0 py-3 pr-8 w-full bg-flinkGray text-left placeholder-flinkGray-light cursor-pointer"
          value={querySearch}
          name={name}
          placeholder={placeholder}
          onChange={(event) => setQuerySearch(truncateQueryString(event.target.value))}
        />
      </div>
    </div>
  );
};

export default memo(Searchbar);
