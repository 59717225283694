import { RefObject, useEffect, useRef, useState } from 'react';

const useInfiniteScroll = (): [RefObject<HTMLDivElement>, boolean] => {
  const [position, setPosition] = useState(0);
  const [loadMore, setLoadMore] = useState(false);
  const loaderRef = useRef<HTMLDivElement>(null);
  const positionRef = useRef({});
  positionRef.current = position;

  useEffect(() => {
    if (loaderRef.current) {
      const option = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      };
      const observer = new IntersectionObserver(([entry]) => {
        const currentPosition = entry.boundingClientRect.y;
        const isIntersecting = entry.isIntersecting;
        if (positionRef.current > currentPosition && isIntersecting) {
          setLoadMore(true);
        } else {
          setLoadMore(false);
        }
        setPosition(currentPosition);
      }, option);
      observer.observe(loaderRef.current);
      return () => observer.disconnect();
    }
  }, [loaderRef]);

  return [loaderRef, loadMore];
};

export default useInfiniteScroll;
