import React from 'react';
import ReactDOMClient from 'react-dom/client';
import singleSpaReact from 'single-spa-react';
import Root from './root.component';
import sdk from '@hubportal/sdk';

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: Root,
  errorBoundary(err: Error, info: React.ErrorInfo) {
    sdk.reportError(err, '@flapp/stock-change-log', info);
    return (
      <div>
        <h1>Something went wrong.</h1>
      </div>
    );
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
