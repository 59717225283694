const supportedLocales = ['de-DE', 'de-AT', 'fr-FR', 'nl-NL'];
const DEFAULT_LOCAL = 'de-DE';

export const formattedDateTime = (event) => {
  const userLang = window.navigator.language;
  const locale = supportedLocales.includes(userLang) ? userLang : DEFAULT_LOCAL;
  return {
    formattedDate: new Date(event).toLocaleDateString(locale),
    formattedTime: new Date(event).toLocaleTimeString(locale),
  };
};
