import { HubStockChangeLogs, V1InventoryChangeReason } from 'graphql/__generated__/schemas';
import { REASONS, REASONS_CORRECTIONS, REASONS_OTHERS } from 'lib/constants';
import { memo } from 'react';
import { useTranslation } from 'hooks';
import styled from 'styled-components';
import { formattedDateTime } from 'utils/formatDateTime';

const ReasonBadge = styled.div<{ reason?: string }>`
  padding: 0 0.5rem;
  border-radius: 8px;
  font-weight: bold;
  font-size: 12px;
  height: 44px;
  display: flex;
  align-items: center;
  ${({ reason, theme }) =>
    reason &&
    REASONS_CORRECTIONS[reason] &&
    `
   background-color: ${theme.colors.MARINE_BLUE_LIGHT};
   color: ${theme.colors.MARINE_BLUE};
  `}
  ${({ reason, theme }) =>
    reason === V1InventoryChangeReason.inventory_change_reason_inbound_goods_received &&
    `
   background-color: ${theme.colors.VEGGIE_GREEN_LIGHT};
   color: ${theme.colors.VEGGIE_GREEN};
   `}
  ${({ reason, theme }) =>
    reason &&
    !REASONS_CORRECTIONS[reason] &&
    !REASONS_OTHERS[reason] &&
    reason !== V1InventoryChangeReason.inventory_change_reason_inbound_goods_received &&
    `
   background-color: ${theme.colors.RED_LIGHT};
   color: ${theme.colors.RED}; 
   `}
`;

const QuantityBox = styled.div<{ color: string }>`
  min-width: 44px;
  height: 44px;
  border-radius: 8px;
  background-color: $lightRed;
  font-weight: bold;
  font-size: 14px;
  color: $red;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  overflow: hidden;
  ${({ color, theme }) =>
    color === 'grey' &&
    `
   background-color: ${theme.colors.GRAY_LIGHT};
   color: ${theme.colors.BLACK}; 
  `}
  ${({ color, theme }) =>
    color === 'green' &&
    `
   background-color: ${theme.colors.VEGGIE_GREEN_LIGHT};
   color: ${theme.colors.VEGGIE_GREEN};
  `}
  ${({ color, theme }) =>
    color === 'red' &&
    `
   background-color: ${theme.colors.RED_LIGHT};
   color: ${theme.colors.RED}; 
   `}
  ${({ color, theme }) =>
    color === 'brand' &&
    `
    background-color: ${theme.colors.FLINK_PINK_LIGHT};
    color:  ${theme.colors.FLINK_PINK}; 
   `}
`;

const StockChangeLog = ({ log }: { log: HubStockChangeLogs }) => {
  const { t } = useTranslation();

  if (!log) return null;
  const quantityTo = log.quantityTo;
  const quantityFrom = log.quantityFrom;
  const changeQuantity = quantityTo - quantityFrom;
  const sign = changeQuantity >= 0 ? '+' : '-';
  const quantityChangeAbs = Math.abs(changeQuantity);
  const { formattedDate, formattedTime } = formattedDateTime(log.createdAt);

  return (
    <div className="grid grid-cols-[160px_60px_1fr_250px_120px_120px_170px] gap-x-4 bg-flinkGray my-2 p-5 rounded-lg">
      <div className="flex items-center">
        <ReasonBadge reason={log.reason}>{t(REASONS[log.reason])}</ReasonBadge>
      </div>
      <div className="flex items-center text-xs">{log.sku}</div>
      <div className="flex items-center font-bold break-words text-sm">{log.name}</div>
      <div className="flex items-center text-xs break-words break-all">{log.orderId}</div>
      <div className="flex items-center text-xs break-words">{log.userId}</div>
      <div className="flex items-center">
        <div>
          <div className="font-bold text-sm">{formattedDate}</div>
          <div className="text-xs">{formattedTime}</div>
        </div>
      </div>
      <div className="flex items-center">
        <QuantityBox color="grey" data-testid="from">
          {quantityFrom}
        </QuantityBox>
        <div className="w-2.5 mx-1 font-bold text-center">{sign}</div>
        <QuantityBox color={changeQuantity > 0 ? 'green' : 'red'}>{quantityChangeAbs}</QuantityBox>
        <div className="w-2.5 mx-1 font-bold text-center">=</div>
        <QuantityBox color={'brand'} data-testid="to">
          {quantityTo}
        </QuantityBox>
      </div>
    </div>
  );
};

export default memo(StockChangeLog);
