import { memo, useCallback, useEffect, useState } from 'react';
import { DEBOUNCE_TIME_IN_MS } from 'lib/constants';
import { useTranslation } from 'hooks';

const hideNumberInputArrowTailwindClasses =
  '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none';

function isNumberOutOfRange(number: number, min?: number, max?: number): boolean {
  return (min !== undefined && number < min) || (max !== undefined && number > max);
}

export const VALIDATION_ERROR_MESSAGES_TRANSLATION_KEYS = {
  min: 'data-validation-value-should-be-gte',
  max: 'data-validation-value-should-be-lte',
  range: 'data-validation-value-should-be-between',
} as const;

export type NumberInputProps = {
  name?: string;
  placeholder?: string;
  onChange: (value: number | undefined) => void;
  defaultValue?: number;
  min?: number;
  max?: number;
  inputClassnames?: string;
};

const NumberInput = ({
  defaultValue,
  name,
  placeholder,
  onChange,
  min,
  max,
  inputClassnames = '',
}: NumberInputProps) => {
  const { t } = useTranslation();
  const [inputValue, setValue] = useState<number | undefined>(defaultValue);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        inputValue === undefined ||
        isNaN(inputValue) ||
        isNumberOutOfRange(inputValue, min, max)
      ) {
        onChange(undefined);
      } else {
        onChange(inputValue);
      }
    }, DEBOUNCE_TIME_IN_MS);

    return () => clearTimeout(timer);
  }, [inputValue, max, min, onChange]);

  const getRangeValidationErrorMessage = useCallback(
    (value: number, min?: number, max?: number): string => {
      let errorMessage = '';

      if (isNumberOutOfRange(value, min, max)) {
        if (min !== undefined && max === undefined) {
          errorMessage = t(VALIDATION_ERROR_MESSAGES_TRANSLATION_KEYS.min, { min });
        } else if (min === undefined && max !== undefined) {
          errorMessage = t(VALIDATION_ERROR_MESSAGES_TRANSLATION_KEYS.max, { max });
        } else {
          errorMessage = t(VALIDATION_ERROR_MESSAGES_TRANSLATION_KEYS.range, { min, max });
        }
      }

      return errorMessage;
    },
    [t]
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value;
    // remove the character "e" (if any) which is allowed in the number input due to the exponent notation
    const sanitizedValue = rawValue.replace(/[^0-9-]/g, '');

    if (sanitizedValue === '') {
      setValue(undefined);
      setError('');
    } else {
      const numericValue = parseInt(sanitizedValue);
      const errorMessage = getRangeValidationErrorMessage(numericValue, min, max);

      setValue(numericValue);
      setError(errorMessage);
    }
  };

  return (
    <div className="relative my-4">
      <div className="my-2 px-2 log-filter h-auto">
        <input
          className={`block truncate text-base border-0 py-3 text-left placeholder-flinkGray-light placeholder-opacity-50 ${hideNumberInputArrowTailwindClasses} ${inputClassnames}`}
          type="number"
          name={name}
          aria-label={name}
          placeholder={placeholder}
          value={inputValue ?? ''}
          onChange={handleInputChange}
          min={min}
          max={max}
        />
      </div>
      {error && <div className="text-red text-xs">{error}</div>}
    </div>
  );
};

export default memo(NumberInput);
