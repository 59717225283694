import { useTranslation } from 'hooks';
import StockChangeLogView from './StockChangeLogView';

const StockChangeLogs = ({ hubSlug }: { hubSlug: string }) => {
  const { t } = useTranslation();

  return (
    <div className="bg-flinkGray-medium text-flinkGray-light h-full min-h-screen px-10 pt-10">
      <div className="relative">
        <h1 className="text-3xl md:text-5xl mb-8 font-medium text-white" data-testid="Title">
          {`${t('overview_stockchange_log')} 🚀`}
        </h1>
        <StockChangeLogView hubSlug={hubSlug} />
      </div>
    </div>
  );
};

export default StockChangeLogs;
