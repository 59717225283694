import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import { memo, useCallback, useEffect, useRef, useState } from 'react';

import { useKeyPress, useOnClickOutside } from '@flpkg/hooks';
import { useTranslation } from 'hooks';
import { REASONS, REASONS_CORRECTIONS, REASONS_GENERAL, REASONS_OTHERS } from 'lib/constants';

type ReasonSelectorProps = {
  name?: string;
  placeholder?: string;
  onChange: (options: string[]) => void;
  isDisabled?: boolean;
  variant?: string;
};

const ReasonSelector = ({
  name,
  placeholder,
  isDisabled,
  onChange,
  variant = 'large',
}: ReasonSelectorProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const escape = useKeyPress('Escape');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const { t } = useTranslation();
  const allReasons = Object.keys(REASONS);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (escape && isOpen) {
      onClose();
    }
  }, [escape, isOpen, onClose]);

  useOnClickOutside(ref, null, () => {
    if (isOpen) {
      onClose();
    }
  });

  useEffect(() => {
    if (selectedItems.length) {
      onChange(selectedItems);
    } else {
      onChange([]);
    }
  }, [onChange, selectedItems]);

  const onChangeOption = (option: string) => {
    if (!selectedItems.includes(option)) {
      setSelectedItems((selectedItems) => [...selectedItems, option]);
    } else {
      setSelectedItems((selectedItems) => selectedItems.filter((item) => item !== option));
    }
  };

  const toggleAllReasons = () => {
    if (selectedItems.length !== allReasons.length) {
      setSelectedItems(allReasons);
    } else {
      setSelectedItems([]);
    }
  };

  const Icon = isOpen ? ChevronUpIcon : ChevronDownIcon;

  return (
    <div className={`${variant} relative my-4`} ref={ref}>
      <button
        className="relative cursor-default log-filter"
        onClick={() => setIsOpen(!isOpen)}
        disabled={isDisabled}
        type="button"
      >
        <input
          className="block truncate text-base border-0 py-3 pr-8 w-full text-left placeholder-flinkGray-light cursor-default disabled:cursor-not-allowed caret-transparent"
          readOnly
          value={`${t('filter_log_reasons')} ${selectedItems.length || ''}`}
          disabled={isDisabled}
          name={name}
          placeholder={placeholder}
        />
        <span className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
          <Icon className="w-5 h-5 text-flinkGray" />
        </span>
      </button>
      {isOpen && (
        <div>
          <ul className="absolute w-full min-w-60 z-10 bg-flinkGray max-h-96 rounded-md py-1 border-0 overflow-auto text-sm">
            <li className="group">
              <button
                className="flex items-center justify-start p-2 my-0.5 cursor-default group-hover:bg-flinkGray-medium w-full border-b-0 text-base"
                onClick={toggleAllReasons}
                type="button"
              >
                {selectedItems.length === allReasons.length ? (
                  <span className="text-flinkGray-medium h-5 w-5 bg-flinkPink">
                    <ChevronDownIcon className="h-5 w-5" />
                  </span>
                ) : (
                  <span className="h-5 w-5 bg-flinkGray-medium group-hover:bg-flinkGray"></span>
                )}
                <span className="ml-3 block truncate">{t('filter_reasons_all')}</span>
              </button>
            </li>
            <li className="text-base px-2 font-bold py-2">{t('filter_reasons_general')}</li>
            {Object.entries(REASONS_GENERAL).map(([reasonId, translationKey]) => (
              <li key={reasonId} className="group">
                <button
                  className="flex items-center justify-start p-2 my-0.5 cursor-default group-hover:bg-flinkGray-medium w-full border-b-0 text-base"
                  onClick={() => onChangeOption(reasonId)}
                  type="button"
                >
                  {selectedItems.includes(reasonId) ? (
                    <span className="text-flinkGray-medium h-5 w-5 bg-flinkPink">
                      <ChevronDownIcon className="h-5 w-5" />
                    </span>
                  ) : (
                    <span className="h-5 w-5 bg-flinkGray-medium group-hover:bg-flinkGray"></span>
                  )}
                  <span className="ml-3 block truncate">{t(translationKey)}</span>
                </button>
              </li>
            ))}
            <li className="text-base px-2 font-bold py-2">{t('filter_reasons_corrections')}</li>
            {Object.entries(REASONS_CORRECTIONS).map(([reasonId, translationKey]) => (
              <li key={reasonId} className="group">
                <button
                  className="flex items-center justify-start p-2 my-0.5 cursor-default group-hover:bg-flinkGray-medium w-full border-b-0 text-base"
                  onClick={() => onChangeOption(reasonId)}
                  type="button"
                >
                  {selectedItems.includes(reasonId) ? (
                    <span className="text-flinkGray-medium h-5 w-5 bg-flinkPink">
                      <ChevronDownIcon className="h-5 w-5" />
                    </span>
                  ) : (
                    <span className="h-5 w-5 bg-flinkGray-medium group-hover:bg-flinkGray"></span>
                  )}
                  <span className="ml-3 block truncate">{t(translationKey)}</span>
                </button>
              </li>
            ))}
            <li className="text-base px-2 font-bold py-2">{t('filter_reasons_others')}</li>
            {Object.entries(REASONS_OTHERS).map(([reasonId, translationKey]) => (
              <li key={reasonId} className="group">
                <button
                  className="flex items-center justify-start p-2 my-0.5 cursor-default group-hover:bg-flinkGray-medium w-full border-b-0 text-base"
                  onClick={() => onChangeOption(reasonId)}
                  type="button"
                >
                  {selectedItems.includes(reasonId) ? (
                    <span className="text-flinkGray-medium h-5 w-5 bg-flinkPink">
                      <ChevronDownIcon className="h-5 w-5" />
                    </span>
                  ) : (
                    <span className="h-5 w-5 bg-flinkGray-medium group-hover:bg-flinkGray"></span>
                  )}
                  <span className="ml-3 block truncate">{t(translationKey)}</span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default memo(ReasonSelector);
