import { DEFAULT_END_TIME, FROM, TO } from './constants';

export const isValidISO8601format = (date) =>
  // eslint-disable-next-line no-useless-escape
  /^([\+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24\:?00)([\.,]\d+(?!:))?)?(\17[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/.test(
    date
  );

export const buildDate = ({ selectedDate, time }) => {
  const dayISO8601 = selectedDate.toISOString().split('T')[0];
  const timeISO8601 = time === DEFAULT_END_TIME ? '23:59:59.999' : `${time}:00.000`;

  const tzOffset = new Date().getTimezoneOffset();
  const timeZoneDirection = tzOffset <= 0 ? '+' : '-';

  const timeZoneHours = String(Math.floor(Math.abs(tzOffset) / 60)).padStart(2, '0');
  const timeZoneMinutes = String(Math.abs(tzOffset) % 60).padStart(2, '0');

  const iso8601date = `${dayISO8601}T${timeISO8601}${timeZoneDirection}${timeZoneHours}:${timeZoneMinutes}`;

  if (!isValidISO8601format(iso8601date)) {
    return null;
  }

  return iso8601date;
};

export const isNumeric = (number) => {
  return /^-?\d+$/.test(number);
};

export const getTimes = (start: number, end: number) => {
  start = start * 2;
  end = end * 2;
  return Array.from({ length: end - start + 1 }, (_, i) => {
    return (((i + start) >> 1) + ':' + ((i + start) % 2) * 3 + '0').replace(/^\d:/, '0$&');
  });
};

export const getTimeOptions = (variant: string, currentStartTimeValue?: string) => {
  if (variant === FROM) {
    return getTimes(6, 23.5).map((time) => {
      return { value: time, label: time };
    });
  } else if (variant === TO && currentStartTimeValue) {
    return getTimes(6, 24).map((time) => {
      return { value: time, label: time, disabled: time <= currentStartTimeValue };
    });
  } else {
    return getTimes(6, 24).map((time) => {
      return { value: time, label: time };
    });
  }
};

export const isValidSKU = (value: string): boolean => /^[0-9]{8,9}$/.test(value);

export const truncateQueryString = (queryString: string): string => {
  const maxLength = 255;
  if (queryString.length <= maxLength) {
    return queryString;
  } else {
    return queryString.slice(0, maxLength);
  }
};
