import React, { useState } from 'react';
import App from './App';
import sdk from '@hubportal/sdk';
import './tailwind.scss';

export default function Root() {
  const [hub] = useState(sdk.getHub());
  const hubSlug = hub?.slug?.toLowerCase() || '';

  return (
    <React.StrictMode>
      <App hubSlug={hubSlug} />
    </React.StrictMode>
  );
}
