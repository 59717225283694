import { V1InventoryChangeReason } from 'graphql/__generated__/schemas';

export const REASONS_GENERAL = {
  [V1InventoryChangeReason.inventory_change_reason_order_created]: 'filter_reasons_ordercreated',
  [V1InventoryChangeReason.inventory_change_reason_order_cancelled]:
    'filter_reasons_order_cancelled',
  [V1InventoryChangeReason.inventory_change_reason_inbound_goods_received]:
    'filter_reasons_productdelivered',
  [V1InventoryChangeReason.inventory_change_reason_outbound_product_expired]:
    'filter_reasons_productexpired',
  [V1InventoryChangeReason.inventory_change_reason_outbound_product_damaged]:
    'filter_reasons_productdamaged',
  [V1InventoryChangeReason.inventory_change_reason_outbound_delivery_product_expired]:
    'filter_reasons_productdeliveryexpired',
  [V1InventoryChangeReason.inventory_change_reason_outbound_delivery_product_damaged]:
    'filter_reasons_productdeliverydamaged',
  [V1InventoryChangeReason.inventory_change_reason_outbound_too_good_to_go]:
    'filter_reasons_toogoodtogo',
  [V1InventoryChangeReason.inventory_change_reason_inbound_bulk]: 'filter_reasons_bulkinbound',
  [V1InventoryChangeReason.inventory_change_reason_unspecified]: 'filter_reasons_unspecified',
} as const;

export const REASONS_CORRECTIONS = {
  [V1InventoryChangeReason.inventory_change_reason_low_stock_check]: 'filter_reasons_low-stock',
  [V1InventoryChangeReason.inventory_change_reason_rolling_inventory_check]:
    'filter_reasons_rolling-inventory',
  [V1InventoryChangeReason.inventory_change_reason_out_of_stock_check]:
    'filter_reasons_out-of-stock-check',
  [V1InventoryChangeReason.inventory_change_reason_expensive_check]: 'filter_reasons_expensive',
  [V1InventoryChangeReason.inventory_change_reason_eoy_inventory_check]:
    'filter_reasons_eoy_inventory_check',
  [V1InventoryChangeReason.inventory_change_reason_eoy_inventory_recount]:
    'filter_reasons_eoy_inventory_recount',
  [V1InventoryChangeReason.inventory_change_reason_correction]: 'filter_reasons_other',
} as const;

export const REASONS_OTHERS = {
  [V1InventoryChangeReason.inventory_change_reason_inbound_stock_transfer]:
    'filter_reasons_inbound_stock_transfer',
  [V1InventoryChangeReason.inventory_change_reason_outbound_equipment_failure]:
    'filter_reasons_equipment_failure',
  [V1InventoryChangeReason.inventory_change_reason_outbound_hub_closure]:
    'filter_reasons_hub_closure',
  [V1InventoryChangeReason.inventory_change_reason_outbound_stock_transfer]:
    'filter_reasons_outbound_stock_transfer',
  [V1InventoryChangeReason.inventory_change_reason_product_recall]: 'filter_reasons_product_recall',
} as const;

export const REASONS = {
  ...REASONS_GENERAL,
  ...REASONS_CORRECTIONS,
  ...REASONS_OTHERS,
} as const;

export const TODAY = new Date();
export const YESTERDAY = new Date();
YESTERDAY.setDate(YESTERDAY.getDate() - 1);

export const DEFAULT_START_TIME = '06:00';
export const DEFAULT_END_TIME = '24:00';

export const PAGE_SIZE = 50;

export const DEBOUNCE_TIME_IN_MS = 800;

export const FROM = 'from';
export const TO = 'to';

export const DAYSELECT_OPTIONS = {
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  PREVIOUS_X_DAYS: 'previous-x-days',
  CUSTOM: 'custom',
} as const;
