import { memo, useEffect, useState } from 'react';
import { SearchIcon, XIcon } from '@heroicons/react/solid';
import { DEBOUNCE_TIME_IN_MS } from 'lib/constants';
import { useTranslation } from 'hooks';

type SearchbarProps = {
  onChange: (userID: string) => void;
};

const UserIdSearch = ({ onChange }: SearchbarProps) => {
  const { t } = useTranslation();
  const [userID, setUserId] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (userID) {
        if (/^\d{6,8}$/.test(userID)) {
          setError(false);
          onChange(userID);
        } else {
          setError(true);
          onChange('');
        }
      } else {
        setError(false);
        onChange('');
      }
    }, DEBOUNCE_TIME_IN_MS);

    return () => clearTimeout(timer);
  }, [onChange, userID]);

  const Icon = userID ? XIcon : SearchIcon;

  return (
    <div className="large relative my-4 w-1/5">
      <div className="relative flex items-center bg-flinkGray rounded">
        <Icon
          data-testid="clear-icon"
          className="pl-2 cursor-pointer w-8 h-8"
          onClick={() => setUserId('')}
        />
        <input
          type="number"
          pattern="\d{6,8}"
          className="block truncate text-base border-0 py-3 pr-8 w-full bg-flinkGray text-left placeholder-flinkGray-light cursor-pointer"
          value={userID}
          name="search_by_employee-id"
          placeholder={t('filter_log_search_by_employee-id')}
          onChange={(event) => {
            const value = event.target.value;
            if (/^\d*$/.test(value) && value.length <= 8) {
              setUserId(value);
            }
          }}
        />
      </div>
      {error && (
        <p className="text-red text-xs mt-1">{t('filter_log_search_by_employee-id.error')}</p>
      )}
    </div>
  );
};

export default memo(UserIdSearch);
